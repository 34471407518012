import React, { Fragment, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateCompany } from "../../store/actions"
import { useNavigate } from "react-router-dom"

const AddCompnay = props => {
  document.title =
    "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = [
    { title: "Cognisun", link: "#" },
    { title: "StringMap", link: "#" },
    { title: "StringMap1", link: "#" },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    props.setBreadcrumbItems("Add Company", breadcrumbItems)
  }, [setValue])
  const onSubmit = async data => {
    try {
      const response = await dispatch(
        addUpdateCompany({
          ...data,
          OperationType: "I",
        }),
      )
      if (response.type == "Add_Company") {
        toast.success("Company Add Successfully")
      } else {
        console.error("Failed to add/update company:", response)
        toast.error("Failed to add/update company.")
      }
    } catch (error) {
      console.error("Failed to add/update company:", error)
      toast.error("Failed to add/update company.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Compnay Info
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="CompanyName">Company Name *</Label>
                      <input
                        type="text"
                        {...register("CompanyName", { required: true })}
                        placeholder="Enter Company Name "
                        className="form-control"
                      />
                      <span className="text-danger">
                        {errors.CompanyName && "Compnay Name is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>GST Number</Label>
                      <input
                        className="form-control"
                        name="GSTNumber"
                        type="GSTNumber"
                        {...register("GSTNumber")}
                        placeholder="Enter GSTNumber "
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Contact Number *</Label>
                      <input
                        className="form-control"
                        name="ContactNumber"
                        type="number"
                        placeholder="Enter ContactNumber"
                        {...register("ContactNumber", {
                          required: true,
                          maxLength: 10,
                        })}
                      />
                      <span className="text-danger">
                        {errors.ContactNumber && "ContactNumber is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>CompanyEmail *</Label>
                      <input
                        className="form-control"
                        name="CompanyEmail"
                        placeholder="Enter Valid Email"
                        type="email"
                        {...register("CompanyEmail", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.CompanyEmail && "CompanyEmail is required."}
                    </span>
                  </Col>
                </Row>

                <CardTitle tag="h4" className="mb-10">
                  Address Information
                </CardTitle>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="control-Label" htmlFor="Address">
                        Address *
                      </Label>
                      <textarea
                        {...register("Address", { required: true })}
                        className="form-control"
                      />
                      <span className="text-danger">
                        {errors.Address && "Enter Valid Address"}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Country *</Label>
                      <input
                        className="form-control"
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                        {...register("Country", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.Country && "Country is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>District *</Label>
                      <input
                        className="form-control"
                        name="District"
                        placeholder="Enter District"
                        type="text"
                        {...register("District", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.District && "District is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Address Type *</Label>
                      <input
                        className="form-control"
                        name="AddressType"
                        placeholder="Enter AddressType "
                        type="text"
                        {...register("AddressType", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.AddressType && "AddressType is required."}
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label>State *</Label>
                      <input
                        className="form-control"
                        name="State"
                        placeholder="Enter State"
                        type="text"
                        {...register("State", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.State && "State is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>PinCode *</Label>
                      <input
                        className="form-control"
                        name="PinCode"
                        placeholder="Enter PinCode"
                        type="text"
                        {...register("PinCode", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.PinCode && "PinCode is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <CardTitle tag="h4" className="mb-10">
                  Owner Information
                </CardTitle>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>First Name *</Label>
                      <input
                        className="form-control"
                        name="FirstName "
                        placeholder="Enter First Name"
                        type="text"
                        {...register("FirstName", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.FirstName && "FirstName is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name *</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="LastName"
                        placeholder="Enter Last Name"
                        {...register("LastName", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.LastName && "LastName is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>CountryCode *</Label>
                      <input
                        className="form-control"
                        name="CountryCode"
                        placeholder="Enter Country Code "
                        type="text"
                        {...register("CountryCode", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.CountryCode && "CountryCode is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Password*</Label>
                      <input
                        className="form-control"
                        name="Password"
                        type="password"
                        placeholder="Password"
                        {...register("Password", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.Password && "Password is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddCompnay)
