import {
  Add_Loyaltiy,
  Add_Loyaltiy_API_ERROR,
  Add_Loyaltiy_SUCCESS,
  View_Loyaltiy,
  View_Loyaltiy_API_ERROR,
  View_Loyaltiy_SUCCESS,
  View_Loyaltiy_BYID,
  View_Loyaltiy_BYID_API_ERROR,
  View_Loyaltiy_BYID_SUCCESS,
} from "./actionTypes"

export const addUpdateLoyaltity = user => {
  debugger
  return {
    type: Add_Loyaltiy,
    payload: { user },
  }
}

export const addLoyaltitySucess = user => {
  return {
    type: Add_Loyaltiy_SUCCESS,
    payload: user,
  }
}

export const loyaltityApiError = error => {
  return {
    type: Add_Loyaltiy_API_ERROR,
    payload: error,
  }
}

export const allLoyaltiy = () => {
  debugger
  return {
    type: View_Loyaltiy,
  }
}

export const viewLoyaltiySucess = loyaltyData => {
  return {
    type: View_Loyaltiy_SUCCESS,
    payload: loyaltyData,
  }
}

export const viewLoyaltiyApiError = error => {
  return {
    type: View_Loyaltiy_API_ERROR,
    payload: error,
  }
}

export const LoyaltiyById = companyId => {
  debugger
  return {
    type: View_Loyaltiy_BYID,
    payload: { companyId },
  }
}

export const LoyaltiySucessByID = loyaltyData => {
  return {
    type: View_Loyaltiy_BYID_SUCCESS,
    payload: loyaltyData,
  }
}

export const LoyaltiyByIDApiError = error => {
  return {
    type: View_Loyaltiy_BYID_API_ERROR,
    payload: error,
  }
}
