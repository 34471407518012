import {
  Add_PRODUCT,
  Add_PRODUCT_API_ERROR,
  Add_PRODUCT_SUCCESS,
  View_PRODUCT_SUCCESS,
  View_PRODUCT_API_ERROR,
  View_PRODUCT_BYID,
  View_PRODUCT_BYID_API_ERROR,
  View_PRODUCT_BYID_SUCCESS,
  View_PACKAGING_API_ERROR,
  View_PACKAGING_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  companyData: [],
}

const addProduct = (state = initialState, action) => {
  switch (action.type) {
    case Add_PRODUCT:
      state = {
        ...state,
        loading: true,
      }
      break
    case Add_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case Add_PRODUCT_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyData: action.payload,
      }
      break
    case View_PRODUCT_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_PACKAGING_SUCCESS:
      state = {
        ...state,
        loading: false,
        packgingData: action.payload,
      }
      break
    case View_PACKAGING_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_PRODUCT_BYID:
      state = {
        ...state,
        loading: true,
      }
      break
    case View_PRODUCT_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyById: action.payload,
      }
      break
    case View_PRODUCT_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default addProduct
