export const Add_Company = "Add_Company"
export const Add_Company_SUCCESS = "Add_Company_SUCCESS"
export const Add_Company_API_ERROR = "Add_Company_API_ERROR"

export const View_Company = "View_Company"
export const View_Company_SUCCESS = "View_Company_SUCCESS"
export const View_Company_API_ERROR = "View_Company_API_ERROR"

export const View_Company_BYID = "View_Company_BYID"
export const View_Company_BYID_SUCCESS = "View_Company_BYID_SUCCESS"
export const View_Company_BYID_API_ERROR = "View_Company_BYID_API_ERROR"
