import React, { useState, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateCompany, CompanyById } from "../../store/actions"
import { useParams, useNavigate } from "react-router-dom"

const ViewCompnay = props => {
  document.title = "View Company"
  const { CompanyId } = useParams()
  const [editContact, setEditContact] = useState(false)

  const companyData = useSelector(
    state => state.company.companyById && state.company.companyById.data,
  )
  const [data, setData] = useState()
  const breadcrumbItems = [
    // { title: "Cognisun", link: "#" },
    // { title: "StringMap", link: "#" },
    // { title: "StringMap1", link: "#" },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    props.setBreadcrumbItems(
      editContact ? "Edit Company" : "View Company",
      breadcrumbItems,
    )
    const getCompanyDataById = async companyId => {
      try {
        await dispatch(CompanyById(companyId))
      } catch (error) {
        console.log(error)
      }
    }
    getCompanyDataById(CompanyId)
  }, [CompanyId])

  useEffect(() => {
    console.log("CompanyData", companyData)
    setValue("CompanyId", companyData && companyData[0].CompanyId)
    setValue("CompanyName", companyData && companyData[0].CompanyName)
    setValue("CompanyEmail", companyData && companyData[0].CompanyEmail)
    setValue("GSTNumber", companyData && companyData[0].GSTNumber)
    setValue(
      "ContactNumber",
      companyData && companyData[0].CompanyContactNumber,
    )
    setValue("AddressType", companyData && companyData[0].AddressType)
    setValue("Address", companyData && companyData[0].Address)
    setValue("UserId", companyData && companyData[0].UserId)
    setValue("FirstName", companyData && companyData[0].FirstName)
    setValue("LastName", companyData && companyData[0].LastName)
    setValue("CountryCode", companyData && companyData[0].CountryCode)
    setValue("Password", companyData && companyData[0].Password)
    setValue("AddressId", companyData && companyData[0].AddressId)
    setValue("Country", companyData && companyData[0].Country)
    setValue("State", companyData && companyData[0].State)
    setValue("District", companyData && companyData[0].District)
    setValue("PinCode", companyData && companyData[0].PostalCode)
    setData(companyData && companyData[0])
  }, [companyData, setValue])

  const handleInputChange = e => {
    const { name, value } = e.target
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
    setValue(name, value)
  }

  const onSubmit = async data => {
    try {
      const response = await dispatch(
        addUpdateCompany({
          ...data,
          OperationType: "U",
        }),
      )
      console.log("Res", response)
      if (response.type == "Add_Company") {
        toast.success("Company Update Successfully")
        navigate("/Company/allCompany")
      } else {
        console.error("Failed to add/update company:", response)
        toast.error("Failed to add/update company.")
      }
    } catch (error) {
      console.error("Failed to add/update company:", error)
      toast.error("Failed to add/update company.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Compnay Info
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="CompanyName">Company Name *</Label>
                      <input
                        type="text"
                        {...register("CompanyName", { required: true })}
                        placeholder="Enter Company Name "
                        className="form-control"
                        value={data && data.CompanyName}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.CompanyName && "Compnay Name is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>GST Number</Label>
                      <input
                        className="form-control"
                        name="GSTNumber"
                        type="GSTNumber"
                        {...register("GSTNumber")}
                        placeholder="Enter GSTNumber "
                        value={data && data.GSTNumber}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Contact Number *</Label>
                      <input
                        className="form-control"
                        name="ContactNumber"
                        type="number"
                        placeholder="Enter ContactNumber"
                        {...register("ContactNumber", {
                          required: true,
                          maxLength: 10,
                        })}
                        value={data && data.CompanyContactNumber}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.ContactNumber && "ContactNumber is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>CompanyEmail *</Label>
                      <input
                        className="form-control"
                        name="CompanyEmail"
                        placeholder="Enter Valid Email"
                        type="email"
                        {...register("CompanyEmail", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                        value={data && data.CompanyEmail}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.CompanyEmail && "CompanyEmail is required."}
                    </span>
                  </Col>
                </Row>

                <CardTitle tag="h4" className="mb-10">
                  Address Information
                </CardTitle>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="control-Label" htmlFor="Address">
                        Address *
                      </Label>
                      <textarea
                        {...register("Address", { required: true })}
                        className="form-control"
                        value={data && data.Address}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.Address && "Enter Valid Address"}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Country *</Label>
                      <input
                        className="form-control"
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                        {...register("Country", { required: true })}
                        value={data && data.Country}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.Country && "Country is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>District *</Label>
                      <input
                        className="form-control"
                        name="District"
                        placeholder="Enter District"
                        type="text"
                        {...register("District", { required: true })}
                        value={data && data.District}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.District && "District is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Address Type *</Label>
                      <input
                        className="form-control"
                        name="AddressType"
                        placeholder="Enter AddressType "
                        type="text"
                        {...register("AddressType", { required: true })}
                        value={data && data.AddressType}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.AddressType && "AddressType is required."}
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label>State *</Label>
                      <input
                        className="form-control"
                        name="State"
                        placeholder="Enter State"
                        type="text"
                        {...register("State", { required: true })}
                        value={data && data.State}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.State && "State is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>PinCode *</Label>
                      <input
                        className="form-control"
                        name="PinCode"
                        placeholder="Enter PinCode"
                        type="text"
                        {...register("PinCode", { required: true })}
                        value={data && data.PostalCode}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.PinCode && "PinCode is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <CardTitle tag="h4" className="mb-10">
                  Owner Information
                </CardTitle>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>First Name *</Label>
                      <input
                        className="form-control"
                        name="FirstName "
                        placeholder="Enter First Name"
                        type="text"
                        {...register("FirstName", { required: true })}
                        value={data && data.FirstName}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.FirstName && "FirstName is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name *</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="LastName"
                        placeholder="Enter Last Name"
                        {...register("LastName", { required: true })}
                        value={data && data.LastName}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.LastName && "LastName is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>CountryCode *</Label>
                      <input
                        className="form-control"
                        name="CountryCode"
                        placeholder="Enter Country Code "
                        type="text"
                        {...register("CountryCode", { required: true })}
                        value={data && data.CountryCode}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                      <span className="text-danger">
                        {errors.CountryCode && "CountryCode is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Password*</Label>
                      <input
                        className="form-control"
                        name="Password"
                        type="password"
                        placeholder="Password"
                        {...register("Password", { required: true })}
                        value={data && data.Password}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                      <span className="text-danger">
                        {errors.Password && "Password is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-0">
                  <div className="d-flex justify-content-end mt-4 gap-2">
                    {editContact ? (
                      <>
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => setEditContact(false)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary" className="ms-1">
                          Update
                        </Button>
                      </>
                    ) : (
                      <Button color="info" onClick={() => setEditContact(true)}>
                        Edit
                      </Button>
                    )}
                  </div>

                  {/* <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div> */}
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ViewCompnay)
