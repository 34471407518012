export const Add_Loyaltiy = "Add_Loyaltiy"
export const Add_Loyaltiy_SUCCESS = "Add_Loyaltiy_SUCCESS"
export const Add_Loyaltiy_API_ERROR = "Add_Loyaltiy_API_ERROR"

export const View_Loyaltiy = "View_Loyaltiy"
export const View_Loyaltiy_SUCCESS = "View_Loyaltiy_SUCCESS"
export const View_Loyaltiy_API_ERROR = "View_Loyaltiy_API_ERROR"

export const View_Loyaltiy_BYID = "View_Loyaltiy_BYID"
export const View_Loyaltiy_BYID_SUCCESS = "View_Loyaltiy_BYID_SUCCESS"
export const View_Loyaltiy_BYID_API_ERROR = "View_Loyaltiy_BYID_API_ERROR"
