import React, { useState, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateLoyaltity, LoyaltiyById } from "../../store/actions"
import { allPackaging } from "../../store/product/actions"
import { useParams, useNavigate } from "react-router-dom"

const ViewCompnay = props => {
  document.title = "View Company"
  const { LoyaltyPointId } = useParams()
  const [editContact, setEditContact] = useState(false)
  const packgingData = useSelector(
    state => state.product.packgingData && state.product.packgingData.data,
  )
  const companyData = useSelector(
    state => state.loyalty.companyById && state.loyalty.companyById.data,
  )
  const [data, setData] = useState()
  const breadcrumbItems = [
    // { title: "Cognisun", link: "#" },
    // { title: "StringMap", link: "#" },
    // { title: "StringMap1", link: "#" },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const getAllPackgingData = async () => {
    try {
      await dispatch(allPackaging())
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    props.setBreadcrumbItems(
      editContact ? "Edit Company" : "View Company",
      breadcrumbItems,
    )
    console.log(LoyaltyPointId)
    const getLoyaltyDataById = async LoyaltyPointId => {
      try {
        console.log(LoyaltyPointId)
        await dispatch(LoyaltiyById(LoyaltyPointId))
      } catch (error) {
        console.log(error)
      }
    }
    getAllPackgingData()
    getLoyaltyDataById(LoyaltyPointId)
  }, [LoyaltyPointId])

  const handleInputSelectChange = e => {
    const { name, value } = e.target
    console.log("DataSelect", value)
    const selectedType = packgingData[0].find(
      type => type.PackagingId === value,
    )
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
    setValue(name, value)
    setValue("ProductId", selectedType.ProductId)
    setData(prevData => ({
      ...prevData,
      ProductId: selectedType.ProductId,
    }))
  }
  console.log("Packeging", packgingData)
  useEffect(() => {
    console.log("CompanyData", companyData)
    if (companyData && companyData.length > 0) {
      setValue("PackagingId", companyData && companyData[0][0].PackagingId)
      setValue("ProductId", companyData && companyData[0][0].ProductId)
      setValue(
        "EligibilityEndDate",
        companyData && companyData[0][0].EligibilityEndDate,
      )
      setValue(
        "EligibilityStartDate",
        companyData && companyData[0][0].EligibilityStartDate,
      )
      setValue(
        "LoyaltyPointId",
        companyData && companyData[0][0].LoyaltyPointId,
      )
      setValue(
        "EligiblePoints",
        companyData && companyData[0][0].EligiblePoints,
      )
      setData(
        companyData && {
          EligiblePoints: companyData[0][0].EligiblePoints,
          PackagingId: companyData && companyData[0][0].PackagingId,
          LoyaltyPointId: companyData[0][0].LoyaltyPointId,
          ProductId: companyData[0][0].ProductId,
          EligibilityEndDate: companyData[0][0].EligibilityEndDate,
          EligibilityStartDate: companyData[0][0].EligibilityStartDate,
        },
      )
    }
  }, [companyData, setValue])

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log("Name")
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
    setValue(name, value)
  }

  const onSubmit = async data => {
    try {
      const response = await dispatch(
        addUpdateLoyaltity({
          ...data,
          OperationType: "U",
        }),
      )
      console.log("Res", response)
      if (response.type == "Add_Loyaltiy") {
        toast.success("Loyaltiy Add Successfully")
      } else {
        console.error("Failed to add/update Loyaltiy:", response)
        toast.error("Failed to add/update Loyaltiy.")
      }
    } catch (error) {
      console.error("Failed to add/update Loyaltiy:", error)
      toast.error("Failed to add/update Loyaltiy.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Loyalty Info
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="Packging">Packging Name *</Label>
                      <select
                        className="form-control"
                        {...register("PackagingId", { required: true })}
                        onChange={handleInputSelectChange}
                        value={data && data.PackagingId}
                        disabled={!editContact}
                      >
                        <option value="">Select Option</option>
                        {packgingData &&
                          packgingData[0].map(type => (
                            <option
                              key={type.PackagingId}
                              value={type.PackagingId}
                            >
                              {type.Name} {type.Quantity}
                            </option>
                          ))}
                      </select>

                      <span className="text-danger">
                        {errors.PackagingId && "Compnay Name is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Eligible Points</Label>
                      <input
                        className="form-control"
                        name="EligiblePoints"
                        type="number"
                        {...register("EligiblePoints")}
                        placeholder="Enter EligiblePoints "
                        value={data && data.EligiblePoints}
                        onChange={handleInputChange}
                        disabled={!editContact}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Eligible StartDate *</Label>
                      <input
                        className="form-control"
                        name="EligibilityStartDate"
                        type="date"
                        placeholder="Enter Eligibility StartDate"
                        {...register("EligibilityStartDate", {
                          required: true,
                        })}
                        value={data && data.EligibilityStartDate}
                        disabled={!editContact}
                        onChange={handleInputChange}
                      />
                      <span className="text-danger">
                        {errors.EligibilityStartDate &&
                          "StartDate is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Eligible EndDate *</Label>
                      <input
                        className="form-control"
                        name="EligibilityEndDate"
                        placeholder="Enter Eligibility EndDate"
                        type="date"
                        {...register("EligibilityEndDate", {
                          required: true,
                        })}
                        value={data && data.EligibilityEndDate}
                        disabled={!editContact}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.EligibilityEndDate && "End Date is required."}
                    </span>
                  </Col>
                </Row>
                <FormGroup className="mb-0">
                  <div className="d-flex justify-content-end mt-4 gap-2">
                    {editContact ? (
                      <>
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => setEditContact(false)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary" className="ms-1">
                          Update
                        </Button>
                      </>
                    ) : (
                      <Button color="info" onClick={() => setEditContact(true)}>
                        Edit
                      </Button>
                    )}
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ViewCompnay)
