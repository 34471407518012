import React, { useState, Fragment, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  Table,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateProduct } from "../../store/actions"
import { useNavigate } from "react-router-dom"
import MultipleImageUpload from "../../pages/MultipleImages.js"

const AddProduct = props => {
  document.title =
    "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const dispatch = useDispatch()
  const [packageInfo, setPackageInfo] = useState([])
  const [quantity, setQuantity] = useState("")
  const [unit, setUnit] = useState("")
  const [price, setPrice] = useState("")
  const [images, setImages] = useState([])
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm()

  const handleAddPackage = () => {
    console.log("Wunitu", quantity, unit, price)
    if (quantity && unit && price) {
      setPackageInfo([
        ...packageInfo,
        { quantity, unit, price, OperationType: "I" },
      ])
      setQuantity("")
      setUnit("")
      setPrice("")
    } else {
      toast.error("Please fill in all fields for package info.")
    }
  }
  const handleImagesUpload = uploadedImages => {
    setImages(uploadedImages)
  }
  const handleRemovePackage = index => {
    const newPackageInfo = [...packageInfo]
    newPackageInfo.splice(index, 1)
    setPackageInfo(newPackageInfo)
  }

  useEffect(() => {}, [setValue])
  const onSubmit = async data => {
    try {
      if (packageInfo.length === 0) {
        toast.error("Please add at least one package info.")
        return
      }
      console.log("Image", images)
      const formData = new FormData()
      images.forEach(image => {
        if (image instanceof File) {
          formData.append("image", image)
        } else {
          console.error("Image is not a File:", image)
        }
      })

      formData.append("ProductName", data.ProductName)
      formData.append("Category", data.Category)
      formData.append("link", data.link)
      formData.append("note", data.note)
      formData.append("packageInfo", JSON.stringify(packageInfo))
      formData.append("OperationType", "I")

      const response = await dispatch(addUpdateProduct(formData))
      if (response.type == "Add_PRODUCT") {
        toast.success("Product Add Successfully")
      } else {
        console.error("Failed to add/update product:", response)
        toast.error("Failed to add/update product.")
      }
    } catch (error) {
      console.error("Failed to add/update product:", error)
      toast.error("Failed to add/update product.")
    }
  }
  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardTitle
              tag="h4"
              className="border-bottom bg-primary p-3 mb-0 text-white"
            >
              New Product
            </CardTitle>
            <CardBody className="bg-light">
              <CardTitle tag="h4" className="mb-0">
                Basic Info
              </CardTitle>
            </CardBody>
            <AvForm onSubmit={handleSubmit(onSubmit)}>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="control-Label" htmlFor="ProductName">
                        Product Name *
                      </Label>
                      <div className="mb-2">
                        <input
                          type="text"
                          {...register("ProductName", { required: true })}
                          className="form-control"
                        />
                      </div>
                      <span className="text-danger">
                        {errors.ProductName && "Product Name is required."}
                      </span>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label className="control-Label" htmlFor="Category">
                        Product Category *
                      </Label>
                      <input
                        className="form-control"
                        name="Category"
                        placeholder="Enter Category"
                        type="text"
                        {...register("Category", { required: true })}
                      />
                      {/* <div className="mb-2">
                        <select
                          className="form-control"
                          {...register("Category", { required: true })}
                        >
                          <option value="">Select Option</option>
                          {category.map(type => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      <span className="text-danger">
                        {errors.Category && "Please select Category."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="link">Video Link</Label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("link")}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.link && "Please Add Link."}
                    </span>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="note">Discripation</Label>
                      <div className="mb-2">
                        <textarea
                          type="text"
                          className="form-control"
                          {...register("note")}
                          placeholder=""
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="bg-light">
                <CardTitle tag="h4" className="mb-0">
                  Package Info
                </CardTitle>
              </CardBody>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="quantity">Packing *</Label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("quantity")}
                        value={quantity}
                        onChange={e => setQuantity(e.target.value)}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.quantity && "Please Add quantity."}
                    </span>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="control-Label" htmlFor="unit">
                        Unit*
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("unit")}
                        value={unit}
                        onChange={e => setUnit(e.target.value)}
                      />
                      {/* <div className="mb-2">
                        <select
                          className="form-control"
                          {...register("unit")}
                          onChange={handleInputChange}
                          value={unit}
                        >
                          <option value="">Select Option</option>
                          {unitList.map(type => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      <span className="text-danger">
                        {errors.unit && "Please select Category."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="price">Price*</Label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("price")}
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.price && "Please Add price."}
                    </span>
                  </Col>
                </Row>
                <Button color="info" onClick={handleAddPackage}>
                  Add Package Info
                </Button>
                <div style={{ marginTop: 10 }}>
                  {packageInfo.length > 0 && (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Quantity</th>
                          <th>Unit</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageInfo.map((packageItem, index) => (
                          <tr>
                            <td>{packageItem.quantity}</td>
                            <td>{packageItem.unit}</td>
                            <td>{packageItem.price}</td>
                            <td>
                              <Button
                                color="danger"
                                onClick={() => handleRemovePackage(index)}
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </CardBody>
              <CardBody className="bg-light">
                <CardTitle tag="h4" className="mb-0">
                  Product Image
                </CardTitle>
              </CardBody>
              <CardBody>
                <MultipleImageUpload onImagesUpload={handleImagesUpload} />
                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </CardBody>
            </AvForm>
          </Card>
        </Col>
      </Row>
    </div>
  )
  // return (
  //   <React.Fragment>
  //     <Row>
  //       <Col xl={12}>
  //         <Card>
  //           <CardBody>
  //             <CardTitle tag="h4" className="mb-10">
  //               Compnay Info
  //             </CardTitle>

  //             <AvForm onSubmit={handleSubmit(onSubmit)}>
  //               <Row>
  //                 <Col md="6">
  //                   <FormGroup>
  //                     <Label htmlFor="CompanyName">Company Name *</Label>
  //                     <input
  //                       type="text"
  //                       {...register("CompanyName", { required: true })}
  //                       placeholder="Enter Company Name "
  //                       className="form-control"
  //                     />
  //                     <span className="text-danger">
  //                       {errors.CompanyName && "Compnay Name is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>GST Number</Label>
  //                     <input
  //                       className="form-control"
  //                       name="GSTNumber"
  //                       type="GSTNumber"
  //                       {...register("GSTNumber")}
  //                       placeholder="Enter GSTNumber "
  //                     />
  //                   </FormGroup>
  //                 </Col>
  //                 <Col md="6">
  //                   <FormGroup>
  //                     <Label>Contact Number *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="ContactNumber"
  //                       type="number"
  //                       placeholder="Enter ContactNumber"
  //                       {...register("ContactNumber", {
  //                         required: true,
  //                         maxLength: 10,
  //                       })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.ContactNumber && "ContactNumber is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>CompanyEmail *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="CompanyEmail"
  //                       placeholder="Enter Valid Email"
  //                       type="email"
  //                       {...register("CompanyEmail", {
  //                         required: true,
  //                         pattern: /^\S+@\S+$/i,
  //                       })}
  //                     />
  //                   </FormGroup>
  //                   <span className="text-danger">
  //                     {errors.CompanyEmail && "CompanyEmail is required."}
  //                   </span>
  //                 </Col>
  //               </Row>

  //               <CardTitle tag="h4" className="mb-10">
  //                 Address Information
  //               </CardTitle>
  //               <Row>
  //                 <Col>
  //                   <FormGroup>
  //                     <Label className="control-Label" htmlFor="Address">
  //                       Address *
  //                     </Label>
  //                     <textarea
  //                       {...register("Address", { required: true })}
  //                       className="form-control"
  //                     />
  //                     <span className="text-danger">
  //                       {errors.Address && "Enter Valid Address"}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>Country *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="Country"
  //                       placeholder="Enter country"
  //                       type="text"
  //                       {...register("Country", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.Country && "Country is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>District *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="District"
  //                       placeholder="Enter District"
  //                       type="text"
  //                       {...register("District", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.District && "District is required."}
  //                     </span>
  //                   </FormGroup>
  //                 </Col>
  //                 <Col>
  //                   <FormGroup>
  //                     <Label>Address Type *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="AddressType"
  //                       placeholder="Enter AddressType "
  //                       type="text"
  //                       {...register("AddressType", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.AddressType && "AddressType is required."}
  //                     </span>
  //                   </FormGroup>

  //                   <FormGroup>
  //                     <Label>State *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="State"
  //                       placeholder="Enter State"
  //                       type="text"
  //                       {...register("State", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.State && "State is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>PinCode *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="PinCode"
  //                       placeholder="Enter PinCode"
  //                       type="text"
  //                       {...register("PinCode", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.PinCode && "PinCode is required."}
  //                     </span>
  //                   </FormGroup>
  //                 </Col>
  //               </Row>

  //               <CardTitle tag="h4" className="mb-10">
  //                 Owner Information
  //               </CardTitle>
  //               <Row>
  //                 <Col>
  //                   <FormGroup>
  //                     <Label>First Name *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="FirstName "
  //                       placeholder="Enter First Name"
  //                       type="text"
  //                       {...register("FirstName", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.FirstName && "FirstName is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>Last Name *</Label>
  //                     <input
  //                       className="form-control"
  //                       type="text"
  //                       name="LastName"
  //                       placeholder="Enter Last Name"
  //                       {...register("LastName", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.LastName && "LastName is required."}
  //                     </span>
  //                   </FormGroup>
  //                 </Col>
  //                 <Col>
  //                   <FormGroup>
  //                     <Label>CountryCode *</Label>
  //                     <input
  //                       className="form-control"
  //                       name="CountryCode"
  //                       placeholder="Enter Country Code "
  //                       type="text"
  //                       {...register("CountryCode", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.CountryCode && "CountryCode is required."}
  //                     </span>
  //                   </FormGroup>
  //                   <FormGroup>
  //                     <Label>Password*</Label>
  //                     <input
  //                       className="form-control"
  //                       name="Password"
  //                       type="password"
  //                       placeholder="Password"
  //                       {...register("Password", { required: true })}
  //                     />
  //                     <span className="text-danger">
  //                       {errors.Password && "Password is required."}
  //                     </span>
  //                   </FormGroup>
  //                 </Col>
  //               </Row>

  //               <FormGroup className="mb-0">
  //                 <div>
  //                   <Button type="submit" color="primary" className="ms-1">
  //                     Submit
  //                   </Button>{" "}
  //                   <Button type="reset" color="secondary">
  //                     Cancel
  //                   </Button>
  //                 </div>
  //               </FormGroup>
  //             </AvForm>
  //           </CardBody>
  //         </Card>
  //       </Col>
  //     </Row>
  //   </React.Fragment>
  // )
}

export default AddProduct
