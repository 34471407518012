export const Add_PRODUCT = "Add_PRODUCT"
export const Add_PRODUCT_SUCCESS = "Add_PRODUCT_SUCCESS"
export const Add_PRODUCT_API_ERROR = "Add_PRODUCT_API_ERROR"

export const View_PRODUCT = "View_PRODUCT"
export const View_PRODUCT_SUCCESS = "View_PRODUCT_SUCCESS"
export const View_PRODUCT_API_ERROR = "View_PRODUCT_API_ERROR"

export const View_PRODUCT_BYID = "View_PRODUCT_BYID"
export const View_PRODUCT_BYID_SUCCESS = "View_PRODUCT_BYID_SUCCESS"
export const View_PRODUCT_BYID_API_ERROR = "View_PRODUCT_BYID_API_ERROR"

export const View_PACKAGING = "View_PACKAGING"
export const View_PACKAGING_SUCCESS = "View_PACKAGING_SUCCESS"
export const View_PACKAGING_API_ERROR = "View_PACKAGING_API_ERROR"
