import { call, put, takeEvery, select } from "redux-saga/effects"

import { Add_Loyaltiy, View_Loyaltiy, View_Loyaltiy_BYID } from "./actionTypes"
import {
  addLoyaltitySucess,
  loyaltityApiError,
  LoyaltiySucessByID,
  LoyaltiyByIDApiError,
  viewLoyaltiySucess,
  viewLoyaltiyApiError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))
function* addUpdateLoyalty({ payload: { user } }) {
  debugger

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(
      axios.post,
      url.addUpdateLoyaltyUrl,
      user,
      config,
    )
    if (response.status == 200) {
      yield put(addLoyaltitySucess(response.data))
      window.location.href = "/Loyalty/allLoyalty"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(loyaltityApiError(errorMessage))
  }
}

function* fetchAllLoyalty() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Config", config)
    const response = yield call(axios.get, url.getAllLoyaltyUrl, config)
    console.log("Allcom", response)
    yield put(viewLoyaltiySucess(response.data))

    //yield put(viewCompanySucess(response.data))
  } catch (error) {
    yield put(viewLoyaltiyApiError(error))
  }
}

function* fetchLoyaltyByID({ payload: { companyId } }) {
  try {
    console.log("ComapnyDataInFun", companyId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getLoyaltyById}/${companyId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(LoyaltiySucessByID(response.data))
  } catch (error) {
    yield put(LoyaltiyByIDApiError(error))
  }
}

function* LoyaltySaga() {
  yield takeEvery(Add_Loyaltiy, addUpdateLoyalty)
  yield takeEvery(View_Loyaltiy, fetchAllLoyalty)
  yield takeEvery(View_Loyaltiy_BYID, fetchLoyaltyByID)
}

export default LoyaltySaga
