import React, { useState } from "react"
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"
import img1 from "../assets/images/directory-bg.jpg"

const MultipleImageUpload = ({ onImagesUpload }) => {
  const [images, setImages] = useState([])

  const handleImageChange = e => {
    const files = Array.from(e.target.files)
    setImages(files)
    onImagesUpload(files)
  }

  return (
    <div>
      <Row>
        {[0, 1, 2].map(index => (
          <Col md="4" key={index}>
            <Card>
              <label htmlFor={`imageInput-${index}`}>
                <CardImg
                  className="cursor-pointer"
                  top
                  width="100%"
                  src={
                    images.length > index
                      ? URL.createObjectURL(images[index])
                      : img1
                  }
                  alt={`Card image ${index + 1}`}
                />
                <input
                  id={`imageInput-${index}`}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  multiple
                />
              </label>
              <CardBody className="text-center">
                <CardTitle tag="h4" className="mb-1">
                  {images.length > index ? images[index].name : "File Name"}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}
MultipleImageUpload.propTypes = {
  onImagesUpload: PropTypes.func.isRequired, // Add PropTypes for onImagesUpload
}
export default MultipleImageUpload
