import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "reactstrap"
import { connect } from "react-redux"
import React, { useState, useEffect } from "react"
import ReactTable from "react-table-v6"
import { CSVLink } from "react-csv"
import "react-table-v6/react-table.css"
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap"
// Importing necessary CSS
import "primereact/resources/themes/mira/theme.css" // Your preferred theme
import "primereact/resources/primereact.min.css" // Core PrimeReact styles
import { setBreadcrumbItems } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { allCompany } from "../../store/company/actions"
import { useNavigate } from "react-router-dom"

const AllCompany = props => {
  // document.title =
  //   "AddCompnay | Cognisun - Responsive Bootstrap 5 Admin Dashboard"
  // const breadcrumbItems = [
  //   { title: "Cognisun", link: "#" },
  //   { title: "StringMap", link: "#" },
  //   { title: "StringMap2", link: "#" },
  // ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companyData = useSelector(state => state.company.companyData.data)
  const [isOpen, setIsOpen] = useState(false)
  // const [filters, setFilters] = useState({
  //   FirstName: "",
  //   LastName: "",
  //   Email: "",
  //   Phone: "",
  //   City: "",
  //   State: "",
  //   Zip: "",
  //   Country: "",
  //   Gender: "",
  //   Status: "",
  // })

  useEffect(() => {
    //  props.setBreadcrumbItems("All Company", breadcrumbItems)
    getAllCompanyData()
  }, [props])

  const getAllCompanyData = async () => {
    try {
      await dispatch(allCompany())
    } catch (error) {
      console.log(error)
    }
  }

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true
  }

  const filterNumeric = (filter, row) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
  }

  const columns = [
    // { Header: 'ID', accessor: 'CompanyId', sortable: true },
    {
      Header: "Company Number",
      accessor: "CompanyNumber",
      sortable: true,
      filterMethod: filterNumeric,
    },
    {
      Header: "Company Name",
      accessor: "CompanyName",
      sortable: true,
      filterMethod: filterCaseInsensitive,
    },
    {
      Header: "Contact Person Number",
      accessor: "ContactNumber",
      sortable: true,
      filterMethod: filterCaseInsensitive,
    },
    {
      Header: "Contact Person Email",
      accessor: "CompanyEmail",
      sortable: true,
      filterMethod: filterCaseInsensitive,
    },

    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      fixed: "right",
      Cell: ({ row }) => (
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => navigate(`/company/view/${row._original.CompanyId}`)}
          >
            View
          </Button>
        </div>
      ),
    },
  ]

  const exportCSV = () => {
    const headers = columns.slice(0, -1).map(col => col.Header)
    const csvData =
      Array.isArray(companyData) && Array.isArray(companyData[0])
        ? companyData[0]
            .map(item =>
              columns.slice(0, -1).reduce((acc, col) => {
                acc[col.accessor] = item[col.accessor]
                return acc
              }, {}),
            )
            .map(item => Object.values(item))
        : []
    return [headers, ...csvData]
  }

  return (
    <>
      <Card>
        <CardTitle
          tag="h4"
          className="border-bottom bg-primary p-3 mb-0 text-white d-flex justify-content-between align-items-center"
        >
          All Company
          <CSVLink data={exportCSV()} filename="Companies.csv">
            <Button color="black">Export CSV</Button>
          </CSVLink>
        </CardTitle>
        <CardBody className="border-top gap-2 d-flex align-items-center"></CardBody>
        <div className="text-center">
          <ReactTable
            columns={columns}
            defaultPageSize={10}
            className="-striped -highlight"
            loading={!companyData}
            data={companyData && companyData[0]}
            filterable
          />
        </div>
      </Card>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(AllCompany)
