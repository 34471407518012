import React, { Fragment, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateLoyaltity } from "../../store/actions"
import { allPackaging } from "../../store/product/actions"
import { useNavigate } from "react-router-dom"

const AddLoyaltiy = props => {
  document.title =
    "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const packgingData = useSelector(
    state => state.product.packgingData && state.product.packgingData.data,
  )
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    getAllPackgingData()
  }, [setValue])

  const getAllPackgingData = async () => {
    try {
      await dispatch(allPackaging())
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log("DataSelect", value)
    const selectedType = packgingData[0].find(
      type => type.PackagingId === value,
    )
    setValue(name, value)
    setValue("ProductId", selectedType.ProductId)
  }
  const onSubmit = async data => {
    try {
      const response = await dispatch(
        addUpdateLoyaltity({
          ...data,
          OperationType: "I",
        }),
      )
      if (response.type == "Add_Loyaltiy") {
        toast.success("Loyaltiy Add Successfully")
      } else {
        console.error("Failed to add/update Loyaltiy:", response)
        toast.error("Failed to add/update Loyaltiy.")
      }
    } catch (error) {
      console.error("Failed to add/update Loyaltiy:", error)
      toast.error("Failed to add/update Loyaltiy.")
    }
  }
  console.log("PacjgingData", packgingData)
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Loyalty Info
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="PackagingId">Packging Name *</Label>
                      <select
                        className="form-control"
                        {...register("PackagingId", { required: true })}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Option</option>
                        {packgingData &&
                          packgingData[0].map(type => (
                            <option
                              key={type.PackagingId}
                              value={type.PackagingId}
                            >
                              {type.Name} {type.Quantity}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger">
                        {errors.PackagingId && "Packging is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Eligible Points</Label>
                      <input
                        className="form-control"
                        name="number"
                        type="EligiblePoints"
                        {...register("EligiblePoints", { required: true })}
                        placeholder="Enter EligiblePoints "
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Eligible StartDate *</Label>
                      <input
                        className="form-control"
                        name="EligibilityStartDate"
                        type="date"
                        placeholder="Enter Eligibility StartDate"
                        {...register("EligibilityStartDate", {
                          required: true,
                        })}
                      />
                      <span className="text-danger">
                        {errors.EligibilityStartDate &&
                          "StartDate is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Eligible EndDate *</Label>
                      <input
                        className="form-control"
                        name="EligibilityEndDate"
                        placeholder="Enter Eligibility EndDate"
                        type="date"
                        {...register("EligibilityEndDate", {
                          required: true,
                        })}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.EligibilityEndDate && "End Date is required."}
                    </span>
                  </Col>
                </Row>

                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddLoyaltiy)
