import { call, put, takeEvery, select } from "redux-saga/effects"

import {
  Add_PRODUCT,
  View_PRODUCT,
  View_PACKAGING,
  View_PRODUCT_BYID,
} from "./actionTypes"
import {
  addUpdateSucess,
  productApiError,
  viewProductSucess,
  viewProductApiError,
  ProductSucessByID,
  ProductByIDApiError,
  viewPackagingSucess,
  viewPackagingApiError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))

function* addUpdateProducts({ payload: { user } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
      },
    }
    const response = yield call(
      axios.post,
      url.addUpdateProductUrl,
      user,
      config,
    )
    if (response.status == 200) {
      yield put(addUpdateSucess(response.data))
      window.location.href = "/Product/allProduct"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(productApiError(errorMessage))
  }
}

function* fetchAllProduct() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Config", config)
    const response = yield call(axios.get, url.getAllProductUrl, config)
    console.log("Allcom", response)
    yield put(viewProductSucess(response.data))
  } catch (error) {
    yield put(viewProductApiError(error))
  }
}

function* fetchAllPackging() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Configpackging", config)
    const response = yield call(axios.get, url.getAllPackging, config)
    console.log("Allcom", response)
    yield put(viewPackagingSucess(response.data))
  } catch (error) {
    yield put(viewPackagingApiError(error))
  }
}

function* fetchAllProductByID({ payload: { companyId } }) {
  try {
    console.log("ComapnyDataInFun", companyId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getProductById}/${companyId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(ProductSucessByID(response.data))
  } catch (error) {
    yield put(ProductByIDApiError(error))
  }
}

function* ProductSaga() {
  yield takeEvery(Add_PRODUCT, addUpdateProducts)
  yield takeEvery(View_PRODUCT, fetchAllProduct)
  yield takeEvery(View_PRODUCT_BYID, fetchAllProductByID)
  yield takeEvery(View_PACKAGING, fetchAllPackging)
}

export default ProductSaga
