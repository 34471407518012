import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Card, CardBody, CardTitle, Collapse, Input, FormGroup, Label, Row, Col } from "reactstrap";
// Importing necessary CSS
import 'primereact/resources/themes/mira/theme.css';  // Your preferred theme
import 'primereact/resources/primereact.min.css';     // Core PrimeReact styles
import { setBreadcrumbItems } from "../../store/actions";

const StringMap2 = (props) => {
    document.title = "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

    const breadcrumbItems = [
        { title: "Cognisun", link: "#" },
        { title: "StringMap", link: "#" },
        { title: "StringMap2", link: "#" },
    ]
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
        Gender: "",
        Status: "",
    });

    useEffect(() => {
        props.setBreadcrumbItems('StringMap2', breadcrumbItems)
    }, [props]);

    // Static data with 15 columns and 100 rows
    const [documentListData] = useState(
        Array.from({ length: 100 }, (_, i) => ({
            ID: i + 1,
            FirstName: `FirstName${i + 1}`,
            LastName: `LastName${i + 1}`,
            Email: `user${i + 1}@example.com`,
            Phone: `555-01${i + 1}`,
            Address: `Address ${i + 1}`,
            City: `City${i + 1}`,
            State: `State${i % 50}`,
            Zip: `100${i % 10}`,
            Country: `Country${i % 5}`,
            Gender: i % 2 === 0 ? "Male" : "Female",
            DateOfBirth: `01/01/${1950 + (i % 50)}`,
            RegistrationDate: `01/01/2024`,
            Status: i % 2 === 0 ? "Active" : "Inactive",
            ProfileURL: `path/to/profile${i + 1}.jpg`,
        }))
    );

    // Filter function
    const filteredData = documentListData.filter((data) => {
        return Object.keys(filters).every((key) =>
            filters[key] === "" || data[key].toString().toLowerCase().includes(filters[key].toLowerCase())
        );
    });

    // Export to CSV feature
    const headers = [
        { label: "ID", key: "ID" },
        { label: "First Name", key: "FirstName" },
        { label: "Last Name", key: "LastName" },
        { label: "Email", key: "Email" },
        { label: "Phone", key: "Phone" },
        { label: "Address", key: "Address" },
        { label: "City", key: "City" },
        { label: "State", key: "State" },
        { label: "Zip", key: "Zip" },
        { label: "Country", key: "Country" },
        { label: "Gender", key: "Gender" },
        { label: "Date of Birth", key: "DateOfBirth" },
        { label: "Registration Date", key: "RegistrationDate" },
        { label: "Status", key: "Status" },
        { label: "Profile URL", key: "ProfileURL" },
    ];

    const csvReport = {
        filename: "Personal_Info_Report.csv",
        headers: headers,
        data: filteredData,
    };

    // Custom paginator buttons
    const paginatorLeft = <Button color="primary" className="ion ion-md-refresh" />;
    const paginatorRight = (
        <CSVLink {...csvReport}>
            <Button color="primary" className="ion ion-md-download" />
        </CSVLink>
    );

    // File link template
    const profileTemplate = (rowData) => (
        <div>
            <a href={rowData.ProfileURL} target="_blank" rel="noopener noreferrer">
                View Profile
            </a>
        </div>
    );

    // Document columns definition
    const documentColumns = [
        { name: "ID", selector: "ID", filter: true, sortable: true },
        { name: "First Name", selector: "FirstName", filter: true, sortable: true },
        { name: "Last Name", selector: "LastName", filter: true, sortable: true },
        { name: "Email", selector: "Email", filter: true, sortable: true },
        { name: "Phone", selector: "Phone", filter: true, sortable: true },
        { name: "Address", selector: "Address", filter: true, sortable: true },
        { name: "City", selector: "City", filter: true, sortable: true },
        { name: "State", selector: "State", filter: true, sortable: true },
        { name: "Zip", selector: "Zip", filter: true, sortable: true },
        { name: "Country", selector: "Country", filter: true, sortable: true },
        { name: "Gender", selector: "Gender", filter: true, sortable: true },
        { name: "Date of Birth", selector: "DateOfBirth", filter: true, sortable: true },
        { name: "Registration Date", selector: "RegistrationDate", filter: true, sortable: true },
        { name: "Status", selector: "Status", filter: true, sortable: true },
        { name: "Profile URL", body: profileTemplate, ignoreRowClick: true, allowOverflow: true, button: true },
    ];

    // Render columns dynamically
    const getDocumentColumns = () =>
        documentColumns.map((item, id) => (
            <Column
                style={{ minWidth: "150px" }}
                key={id}
                sortable={item.sortable}
                body={item?.body}
                filter={item.filter}
                field={item.selector}
                header={item.name}
            />
        ));

    // Handle filter input changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle className="h4">Filter Option</CardTitle>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFlushOne">
                                <button
                                    className="accordion-button fw-medium"
                                    type="button"
                                    onClick={() => setIsOpen(!isOpen)}
                                    style={{ cursor: "pointer" }}
                                >
                                    Filter Data
                                </button>
                            </h2>
                            <Collapse isOpen={isOpen} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="FirstName">First Name</Label>
                                                <Input
                                                    type="text"
                                                    name="FirstName"
                                                    id="FirstName"
                                                    value={filters.FirstName}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="LastName">Last Name</Label>
                                                <Input
                                                    type="text"
                                                    name="LastName"
                                                    id="LastName"
                                                    value={filters.LastName}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Email">Email</Label>
                                                <Input
                                                    type="text"
                                                    name="Email"
                                                    id="Email"
                                                    value={filters.Email}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Phone">Phone</Label>
                                                <Input
                                                    type="text"
                                                    name="Phone"
                                                    id="Phone"
                                                    value={filters.Phone}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="City">City</Label>
                                                <Input
                                                    type="text"
                                                    name="City"
                                                    id="City"
                                                    value={filters.City}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="State">State</Label>
                                                <Input
                                                    type="text"
                                                    name="State"
                                                    id="State"
                                                    value={filters.State}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Zip">Zip</Label>
                                                <Input
                                                    type="text"
                                                    name="Zip"
                                                    id="Zip"
                                                    value={filters.Zip}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Country">Country</Label>
                                                <Input
                                                    type="text"
                                                    name="Country"
                                                    id="Country"
                                                    value={filters.Country}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Gender">Gender</Label>
                                                <Input
                                                    type="text"
                                                    name="Gender"
                                                    id="Gender"
                                                    value={filters.Gender}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="Status">Status</Label>
                                                <Input
                                                    type="text"
                                                    name="Status"
                                                    id="Status"
                                                    value={filters.Status}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <DataTable
                scrollable
                value={filteredData}
                showGridlines
                scrollHeight="600px"
                scrollWidth="100%"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={filteredData.length}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                filterDisplay="row"
            >
                {getDocumentColumns()}
            </DataTable>
        </>
    );
};

export default connect(null, { setBreadcrumbItems })(StringMap2);
