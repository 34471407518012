import {
  Add_Company,
  Add_Company_API_ERROR,
  Add_Company_SUCCESS,
  View_Company,
  View_Company_SUCCESS,
  View_Company_API_ERROR,
  View_Company_BYID,
  View_Company_BYID_SUCCESS,
  View_Company_BYID_API_ERROR,
} from "./actionTypes"

export const addUpdateCompany = user => {
  debugger
  return {
    type: Add_Company,
    payload: { user },
  }
}

export const addCompanySucess = user => {
  return {
    type: Add_Company_SUCCESS,
    payload: user,
  }
}

export const compnayApiError = error => {
  return {
    type: Add_Company_API_ERROR,
    payload: error,
  }
}

export const allCompany = () => {
  debugger
  return {
    type: View_Company,
  }
}

export const viewCompanySucess = companyData => {
  return {
    type: View_Company_SUCCESS,
    payload: companyData,
  }
}

export const viewCompanyApiError = error => {
  return {
    type: View_Company_API_ERROR,
    payload: error,
  }
}

export const CompanyById = companyId => {
  debugger
  return {
    type: View_Company_BYID,
    payload: { companyId },
  }
}

export const CompanySucessByID = companyData => {
  return {
    type: View_Company_BYID_SUCCESS,
    payload: companyData,
  }
}

export const CompanyByIDApiError = error => {
  return {
    type: View_Company_BYID_API_ERROR,
    payload: error,
  }
}
