import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import axios from "axios"
import * as url from "../../../helpers/url_helper"

function* loginUser({ payload: { user, history } }) {
  debugger
  try {
    const response = yield call(axios.post, url.POST_LOGIN, {
      LoginId: user.email,
      Password: user.password,
    })
    if (response.status >= 200 && response.status < 300) {
      console.log("LoginUser", response.data.data.accessToken)
      localStorage.setItem("authUser", JSON.stringify(response.data.data))
      yield put(
        loginSuccess(response.data.data, response.data.data.accessToken),
      )
      history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(apiError(errorMessage))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess(true))
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
