export const API_URL = process.env.API_URL
  ? process.env.API_URL
  : "https://krishikaranapi.echonsol.com/api"
// krishikaranapi.echonsol.com/api
//  "http://localhost:8000/api"
// https://krishikaranapi.echonsol.com/api
// LOGIN
export const POST_LOGIN = `${API_URL}/users/login`

// ---------------------------- Old Urls --------------------------------------------
//Company
export const addUpdateCompany = `${API_URL}/company/AddUpdate`

export const getAllCompany = `${API_URL}/company/All`

export const getCompanyById = `${API_URL}/company`

// product

export const addUpdateProductUrl = `${API_URL}/product/AddUpdate`

export const getAllProductUrl = `${API_URL}/product/All`

export const getProductById = `${API_URL}/product`
export const getAllPackging = `${API_URL}/product/dropDown/packaging`

// loyalty

export const addUpdateLoyaltyUrl = `${API_URL}/dealer/loyalty/AddUpdate`

export const getAllLoyaltyUrl = `${API_URL}/dealer//all/loyaltyPoint`

export const getLoyaltyById = `${API_URL}/dealer/loyaltyPoint/loyalty`

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
// export const POST_LOGIN = "/auth/login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"
export const DELETE_MESSAGE = "/delete/message"

//TASKS
export const GET_TASKS = "/tasks"
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"
