import React, { useState, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  Table,
  CardImg,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateProduct, ProductById } from "../../store/actions"
import { useParams, useNavigate } from "react-router-dom"

const ViewProduct = props => {
  document.title = "View Company"
  const { ProductId } = useParams()
  const [editContact, setEditContact] = useState(false)

  const companyData = useSelector(state => state.product.companyById)
  //  state.product.companyById && state.company.companyById.data,
  const [data, setData] = useState()
  const breadcrumbItems = [
    // { title: "Cognisun", link: "#" },
    // { title: "StringMap", link: "#" },
    // { title: "StringMap1", link: "#" },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [packageInfo, setPackageInfo] = useState([])
  const [productImage, setProductImage] = useState([])
  const [quantity, setQuantity] = useState("")
  const [unit, setUnit] = useState("")
  const [price, setPrice] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    const getCompanyDataById = async companyId => {
      try {
        await dispatch(ProductById(companyId))
      } catch (error) {
        console.log(error)
      }
    }
    getCompanyDataById(ProductId)
    props.setBreadcrumbItems(
      editContact ? "Edit Product" : "View Product",
      breadcrumbItems,
    )
  }, [ProductId])

  useEffect(() => {
    console.log("CompanyData", companyData)
    if (companyData && companyData.data.length > 0) {
      console.log("CompanyData", companyData.data[0])
      setValue("ProductName", companyData && companyData.data[0][0].Name)
      setValue("Category", companyData && companyData.data[0][0].Category)
      setValue("link", companyData && companyData.data[0][0].VideoLink)
      setValue("note", companyData && companyData.data[0][0].Note)
      const updatedData = companyData.data[0].map(item => {
        return { ...item, OperationType: "U", updatePackageInfo: false }
      })
      setPackageInfo(updatedData)
      setProductImage(companyData && companyData.data[1])
      console.log("Afterp", companyData.data[0][0])
      setData(
        companyData && {
          ProductName: companyData.data[0][0].Name,
          link: companyData.data[0][0].VideoLink,
          Category: companyData.data[0][0].Category,
          note: companyData.data[0][0].Note,
        },
      )
    }
  }, [companyData, setValue])

  const handleInputChange = e => {
    const { name, value } = e.target
    console.log("ValueData", data)
    console.log(e.target.name)
    console.log("Value", e.target.value)
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
    setValue(name, value)
  }

  const handleAddPackageInfo = () => {
    if (quantity && unit && price) {
      setPackageInfo([
        ...packageInfo,
        { quantity, unit, price, OperationType: "I" },
      ])
      setQuantity("")
      setUnit("")
      setPrice("")
    } else {
      toast.error("Please fill in all fields for package info.")
    }
  }

  const handleAddPackage = index => {
    console.log(packageInfo, "OnSave")
    const updatedPackageInfoState = [...packageInfo]
    updatedPackageInfoState[index] = {
      ...updatedPackageInfoState[index],
      updatePackageInfo: false,
    }
    setPackageInfo(updatedPackageInfoState)
  }

  const handleInputTableChange = (index, key, value) => {
    const updatedPackageInfoState = [...packageInfo]

    updatedPackageInfoState[index][key] = value
    setPackageInfo(updatedPackageInfoState)
  }

  const handleUpdatePackageInfo = index => {
    const updatedPackageInfoState = [...packageInfo]
    updatedPackageInfoState[index] = {
      ...updatedPackageInfoState[index],
      updatePackageInfo: true,
    }
    console.log(updatedPackageInfoState)
    setPackageInfo(updatedPackageInfoState)
  }

  const handleUpdateCancel = index => {
    const updatedPackageInfoState = [...packageInfo]
    updatedPackageInfoState[index] = {
      ...updatedPackageInfoState[index],
      updatePackageInfo: false,
    }
    console.log(updatedPackageInfoState)
    setPackageInfo(updatedPackageInfoState)
  }

  const onSubmit = async data => {
    try {
      if (packageInfo.length === 0) {
        toast.error("Please add at least one package info.")
        return
      }
      const formData = new FormData()
      formData.append("ProductId", packageInfo[0].ProductId)
      formData.append("ProductName", data.ProductName)
      formData.append("Category", data.Category)
      formData.append("link", data.link)
      formData.append("note", data.note)
      formData.append("packageInfo", JSON.stringify(packageInfo))
      formData.append("OperationType", "U")
      const response = await dispatch(addUpdateProduct(formData))
      console.log("Res", response)
      if (response.type == "Add_Company") {
        toast.success("Product Update Successfully")
        navigate("/Company/allCompany")
      } else {
        console.error("Failed to add/update company:", response)
        toast.error("Failed to add/update company.")
      }
    } catch (error) {
      console.error("Failed to add/update company:", error)
      toast.error("Failed to add/update company.")
    }
  }

  return (
    console.log("Data", data),
    (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardTitle
                tag="h4"
                className="border-bottom bg-primary p-3 mb-0 text-white"
              >
                Edit Product
              </CardTitle>
              <CardBody className="bg-light">
                <CardTitle tag="h4" className="mb-0">
                  Basic Info
                </CardTitle>
              </CardBody>
              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="control-Label" htmlFor="ProductName">
                          Product Name *
                        </Label>
                        <div className="mb-2">
                          <input
                            type="text"
                            {...register("ProductName", { required: true })}
                            className="form-control"
                            value={data && data.ProductName}
                            disabled={!editContact}
                            onChange={handleInputChange}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.ProductName && "Product Name is required."}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label className="control-Label" htmlFor="Category">
                          Product Category *
                        </Label>
                        <input
                          className="form-control"
                          name="Category"
                          placeholder="Enter Category"
                          type="text"
                          {...register("Category", { required: true })}
                          disabled={!editContact}
                          onChange={handleInputChange}
                          value={data && data.Category}
                        />
                        {/* <div className="mb-2">
                        <select
                          className="form-control"
                          {...register("Category", { required: true })}
                        >
                          <option value="">Select Option</option>
                          {category.map(type => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                        <span className="text-danger">
                          {errors.Category && "Please select Category."}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="link">Video Link</Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("link")}
                          disabled={!editContact}
                          onChange={handleInputChange}
                          value={data && data.link}
                        />
                      </FormGroup>
                      <span className="text-danger">
                        {errors.link && "Please Add Link."}
                      </span>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="note">Discripation</Label>
                        <div className="mb-2">
                          <textarea
                            type="text"
                            className="form-control"
                            {...register("note")}
                            placeholder=""
                            disabled={!editContact}
                            onChange={handleInputChange}
                            value={data && data.note}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="bg-light">
                  <CardTitle tag="h4" className="mb-0">
                    Package Info
                  </CardTitle>
                </CardBody>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="quantity">Quantity*</Label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("quantity")}
                          value={quantity}
                          onChange={e => setQuantity(e.target.value)}
                        />
                      </FormGroup>
                      <span className="text-danger">
                        {errors.quantity && "Please Add quantity."}
                      </span>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="control-Label" htmlFor="unit">
                          Unit*
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("unit")}
                          value={unit}
                          onChange={e => setUnit(e.target.value)}
                        />
                        {/* <div className="mb-2">
                        <select
                          className="form-control"
                          {...register("unit")}
                          onChange={handleInputChange}
                          value={unit}
                        >
                          <option value="">Select Option</option>
                          {unitList.map(type => (
                            <option key={type.id} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                        <span className="text-danger">
                          {errors.unit && "Please select Category."}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="price">Price*</Label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("price")}
                          value={price}
                          onChange={e => setPrice(e.target.value)}
                        />
                      </FormGroup>
                      <span className="text-danger">
                        {errors.price && "Please Add price."}
                      </span>
                    </Col>
                  </Row>
                  <Button color="info" onClick={handleAddPackageInfo}>
                    Add Package Info
                  </Button>
                  <div style={{ marginTop: 10 }}>
                    {packageInfo.length > 0 && (
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Packing</th>
                            <th>Unit</th>
                            <th>Price</th>
                            {editContact && <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {packageInfo.map((packageItem, index) => (
                            <tr>
                              {!packageItem.updatePackageInfo ? (
                                <td>{packageItem.quantity}</td>
                              ) : (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    // {...register('quantity')}
                                    value={packageItem.quantity}
                                    onChange={e =>
                                      handleInputTableChange(
                                        index,
                                        "quantity",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              )}
                              {!packageItem.updatePackageInfo ? (
                                <td>{packageItem.unit}</td>
                              ) : (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("unit")}
                                    value={unit}
                                    onChange={e => setUnit(e.target.value)}
                                  />
                                  {/* <select
                                  className="form-control"
                                  // {...register('unit')}
                                  onChange={e =>
                                    handleInputTableChange(
                                      index,
                                      "unit",
                                      e.target.value,
                                    )
                                  }
                                  value={packageItem.unit}
                                >
                                  <option value="">Select Option</option>
                                  {unitList.map(type => (
                                    <option key={type.id} value={type.name}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select> */}
                                </td>
                              )}
                              {!packageItem.updatePackageInfo ? (
                                <td>{packageItem.price}</td>
                              ) : (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    // {...register('price')}
                                    value={packageItem.price}
                                    onChange={e =>
                                      handleInputTableChange(
                                        index,
                                        "price",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              )}
                              {editContact ? (
                                <td>
                                  {!packageItem.updatePackageInfo ? (
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleUpdatePackageInfo(index)
                                      }
                                    >
                                      Update
                                    </Button>
                                  ) : (
                                    <div className="d-flex justify-content-around text-center">
                                      <Button
                                        color="primary"
                                        onClick={() => handleAddPackage(index)}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleUpdateCancel(index)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  )}
                                  {/* <Button color="danger" onClick={() => handleRemovePackage(index)}>
                                Remove
                              </Button> */}
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>

                <CardBody className="bg-light">
                  <CardTitle tag="h4" className="mb-0">
                    Product Image
                  </CardTitle>
                </CardBody>
                <CardBody>
                  <Row>
                    <Col>
                      <CardImg
                        className="cursor-pointer"
                        top
                        width="100%"
                        src={productImage[0] && productImage[0].URL}
                        alt={productImage[0] && productImage[0].Filename}
                      />
                    </Col>
                    <Col>
                      <CardImg
                        className="cursor-pointer"
                        top
                        width="100%"
                        src={productImage[1] && productImage[1].URL}
                        alt={productImage[1] && productImage[1].Filename}
                      />
                    </Col>
                    <Col>
                      <CardImg
                        className="cursor-pointer"
                        top
                        width="100%"
                        src={productImage[2] && productImage[2].URL}
                        alt={productImage[2] && productImage[2].Filename}
                      />
                    </Col>
                  </Row>
                  {/* <MultipleImageUpload onImagesUpload={handleImagesUpload} /> */}
                  <FormGroup className="mb-0">
                    <div className="d-flex justify-content-end mt-4 gap-2">
                      {editContact ? (
                        <>
                          <Button
                            type="reset"
                            color="secondary"
                            onClick={() => setEditContact(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                          >
                            Update
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="info"
                          onClick={() => setEditContact(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </FormGroup>
                </CardBody>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </div>
    )
  )
}

export default connect(null, { setBreadcrumbItems })(ViewProduct)
