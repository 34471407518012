import {
  Add_PRODUCT,
  Add_PRODUCT_API_ERROR,
  Add_PRODUCT_SUCCESS,
  View_PRODUCT,
  View_PRODUCT_API_ERROR,
  View_PRODUCT_BYID,
  View_PRODUCT_BYID_API_ERROR,
  View_PRODUCT_BYID_SUCCESS,
  View_PRODUCT_SUCCESS,
  View_PACKAGING,
  View_PACKAGING_API_ERROR,
  View_PACKAGING_SUCCESS,
} from "./actionTypes"

export const addUpdateProduct = user => {
  debugger
  return {
    type: Add_PRODUCT,
    payload: { user },
  }
}

export const addUpdateSucess = user => {
  return {
    type: Add_PRODUCT_SUCCESS,
    payload: user,
  }
}

export const productApiError = error => {
  return {
    type: Add_PRODUCT_API_ERROR,
    payload: error,
  }
}

export const allProduct = () => {
  debugger
  return {
    type: View_PRODUCT,
  }
}

export const viewProductSucess = companyData => {
  return {
    type: View_PRODUCT_SUCCESS,
    payload: companyData,
  }
}

export const viewProductApiError = error => {
  return {
    type: View_PRODUCT_API_ERROR,
    payload: error,
  }
}

export const allPackaging = () => {
  debugger
  return {
    type: View_PACKAGING,
  }
}

export const viewPackagingSucess = companyData => {
  return {
    type: View_PACKAGING_SUCCESS,
    payload: companyData,
  }
}

export const viewPackagingApiError = error => {
  return {
    type: View_PACKAGING_API_ERROR,
    payload: error,
  }
}

export const ProductById = companyId => {
  debugger
  return {
    type: View_PRODUCT_BYID,
    payload: { companyId },
  }
}

export const ProductSucessByID = companyData => {
  return {
    type: View_PRODUCT_BYID_SUCCESS,
    payload: companyData,
  }
}

export const ProductByIDApiError = error => {
  return {
    type: View_PRODUCT_BYID_API_ERROR,
    payload: error,
  }
}
